import { Employee } from "@/apis/models/employee.model";

export interface PayRun {
    id: number;
    payRunId: string;
    tenantId: string;
    payrollId: string;
    name: string;
    processingDate: Date;
    startOfPayPeriod: Date;
    endOfPayPeriod: Date;
    errorDetail: PayRunErrorDetail;
    status: string;
    approvedBy: string;
    approvedOn: Date;
    approvalStatus: string;
    description: string;
    createdBy: string;
    updatedBy: string;
    createdAt: Date;
    updatedAt: Date;
    employeeCompensations: PayRunEmployeeCompensation[];
    narration: string;
}

export interface PayRunErrorDetail {
    id: number;
    errorDetailId: string;
    payRunId: string;
    errorCode: string;
    errorMessage: string;
    errorReason: string;
}

export interface PayRunEmployeeCompensation {
    id: number;
    payRunEmployeeCompensationId: string;
    payRunId: string;
    employeeId: string;
    basicPay: number;
    grossSalary: number;
    additions: PayRunEmployeeCompensationAddition[];
    netPay: number;
    currency: string;
    deductions: PayRunEmployeeCompensationDeduction[];
    provider: PayRunEmployeeCompensationProvider;
    externalTransactionId: string;
    status: string;
    statusAsOf: Date;
    totalDeductions: number;
    totalAdditions: number;
    processedOn: Date;
}

interface PayRunEmployeeCompensationAddition {
    id: number;
    payRunEmployeeCompensationAdditionId: string;
    payRunEmployeeCompensationId: string;
    name: string;
    amount: number;
    type: PayRunEmployeeCompensationAdditionType;
}

enum PayRunEmployeeCompensationAdditionType {
    Other = 0,
    Allowance = 1
}

interface PayRunEmployeeCompensationDeduction {
    id: number;
    payRunEmployeeCompensationDeductionId: string;
    payRunEmployeeCompensationId: string;
    name: string;
    amount: number;
    type: PayRunEmployeeCompensationDeductionType;
}

enum PayRunEmployeeCompensationDeductionType {
    Custom = 0,
    Statutory = 1
}

export interface PayRunEmployeeCompensationProvider {
    id: number;
    employeeCompensationProviderId: string;
    payRunEmployeeCompensationId: string;
    name: string;
    referenceId: string;
    paymentDate: Date;
    paymentStatus: string;
    errorReason: string;
}

export interface Payslip {
    id: number;
    payslipId: string;
    payRunEmployeeCompensationId: string;
    tenantId: string;
    companyName: string;
    employeeId: string;
    employeeFirstName: string;
    employeeLastName: string;
    paymentStatus: string;
    basicPay: number;
    grossPay: number;
    netPay: number;
    currency: string;
    paymentDate: string;
    startOfPayPeriod: string;
    endOfPayPeriod: string;
    additions: PayRunEmployeeCompensationAddition[];
    deductions: PayRunEmployeeCompensationDeduction[];
}

export interface PayRunSummary {
    payRunId: string;
    name: string;
    grossAmount: number;
    numberOfEmployees: number;
    startOfPayPeriod: string;
    endOfPayPeriod: string;
    paymentDate: string;
    payrollFrequency: string;
    paymentStatus: string;
}

export interface Payroll {
    payrollId?: string;
    tenantId?: string;
    frequency?: string;
    startDate?: string;
    processingDay?: number;
    daysBeforeToProcessingRequestApproval?: number;
    isTemplate?: boolean;
    name?: string;
    createdBy?: string;
    createdOn?: string;
    changedOn?: string;
    changedBy?: string;
    approvedOn?: string;
    approvalStatus?: string;
    isPayrunAutoApproved?: boolean;
    isApproverRequired?: boolean;
    approvers?: Array<PayrollApprover>;
    employeesToPay?: Array<PayrollEmployeeToPay>;
    sendPayslips?: boolean;
    lastProcessed?: string;
    nextPaymentDate?: string;
    lastTimeApprovalEmailWasSent?: string;
    processingStatus: string;
}

/// <summary>
/// Represents the processing status of a payroll.
/// </summary>
export enum PayrollProcessingStatus {
    /// <summary>
    /// The payroll is awaiting processing.
    /// </summary>
    AwaitingProcessing = "AwaitingProcessing",

    /// <summary>
    /// The payroll is currently being processed.
    /// </summary>
    Processing = "Processing",

    /// <summary>
    /// The payroll is awaiting payment disbursement.
    /// </summary>
    AwaitingPaymentDisbursement = "AwaitingPaymentDisbursement",

    /// <summary>
    /// The payroll is disbursing payments.
    /// </summary>
    DisbursingPayments = "DisbursingPayments",
}

export interface PayrollEmployeeToPay {
    payrollEmployeeToPayId?: string;
    employeeId?: string;
    status?: string;
    statusAsOfDate?: string;
    approvedBy?: string;
    approvedOn?: string;
}

export interface PayrollApprover {
    id?: number;
    payrollId?: string;
    payrollApproverId?: string;
    employeeId?: string;
    name: string;
    email: string;
    approvalChoice: string;
}

export interface EmployeeCompensationCalculation {
    employeeId: string;
    employeeName: string;
    employeeEmail: string;
    payrollEmployeeToPayId: string;
    basicPay: number;
    grossPay: number;
    netPay: number;
    deductions: PayRunEmployeeCompensationDeduction[];
    additions: PayRunEmployeeCompensationDeduction[];
    transactionFee: number;
    currency: string;
    employee: Employee;
}

export enum EmployeeCompensationCalculationTarget {
    EmployeesOnPayroll = "EmployeesOnPayroll",
    EmployeesNotOnPayroll = "EmployeesNotOnPayroll"
}


export interface PayrollPaymentDates {
    payrollId: string;
    dates: Array<PayrollPaymentDate>;
}

export interface PayrollPaymentDateCalculationRequest {
    country: string;
    payrollFrequency: string;
    initialPaymentDate: string;
}


export interface PayrollPaymentDate {
    day: number;
    month: number;
    year: number;
}

export interface PayrollPaymentDatesGenerated {
    payrollIds: Array<string>;
    id: string;
    date: PayrollPaymentDate;
}

export interface RunPayrunRequest {
    payrunId: string;
    retrying: boolean;
}

export enum PaymentStatus {
    PENDING,
    CREATED,
    SUCCESSFUL,
    FAILED
}

export enum ZambiaCompensationDeductions {
    HealthContribution = "NHIMA",
    PensionContribution = "NAPSA",
    PAYE = "PAYE"
}

/**
 * Payroll Summary
 */
export interface PayrollSummary {
    name: string;
    id: string;
    tenantId: string;
    approvalStatus: string;
    netAmount: number;
    grossAmount: number;
    transactionFee: number;
    numberOfEmployees: number;
    lastProcessed: Date;
    nextPaymentDate: Date;
    processingDay: number;
    frequency: string;
    employeeCompensations: EmployeeCompensationCalculation[];
}

export enum PayrollApprovalChoice {
    None = "None",
    Approve = "Approve",
    Reject = "Reject"
}